<template>
  <div class="register-footer">
    <div class="register-footer__info contacts">
      <slot></slot>
      <div class="register-footer__tip">* обязательно для заполнения</div>
      <div class="contacts__title">Если есть вопросы — свяжитесь с нами</div>
      <div class="contacts-item contacts__email">
        <div class="contacts-item__icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66659 2.66675H13.3333C14.0666 2.66675 14.6666 3.26675 14.6666 4.00008V12.0001C14.6666 12.7334 14.0666 13.3334 13.3333 13.3334H2.66659C1.93325 13.3334 1.33325 12.7334 1.33325 12.0001V4.00008C1.33325 3.26675 1.93325 2.66675 2.66659 2.66675Z"
              stroke="#B2B4B4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.6666 4L7.99992 8.66667L1.33325 4"
              stroke="#B2B4B4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="contacts-item__text">По email</div>
        <div class="contacts-item__contact">
          <a class="contacts-item__contact" href="mailto:supportazmost@astrazeneca.com"
            >supportazmost@astrazeneca.com</a
          >
        </div>
      </div>
      <div v-if="false" class="contacts-item contacts__phone">
        <div class="contacts-item__icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.6667 11.28V13.28C14.6675 13.4657 14.6294 13.6494 14.555 13.8195C14.4807 13.9897 14.3716 14.1424 14.2348 14.2679C14.0979 14.3934 13.9364 14.489 13.7605 14.5485C13.5847 14.6079 13.3983 14.63 13.2134 14.6133C11.1619 14.3904 9.19137 13.6894 7.46004 12.5667C5.84926 11.5431 4.48359 10.1774 3.46004 8.56665C2.33336 6.82745 1.6322 4.84731 1.41337 2.78665C1.39671 2.60229 1.41862 2.41649 1.4777 2.24107C1.53679 2.06564 1.63175 1.90444 1.75655 1.76773C1.88134 1.63102 2.03324 1.52179 2.20256 1.447C2.37189 1.37221 2.55493 1.33349 2.74004 1.33332H4.74004C5.06357 1.33013 5.37723 1.4447 5.62254 1.65567C5.86786 1.86664 6.02809 2.15961 6.07337 2.47998C6.15779 3.12003 6.31434 3.74847 6.54004 4.35332C6.62973 4.59193 6.64915 4.85126 6.59597 5.10057C6.5428 5.34988 6.41928 5.57872 6.24004 5.75998L5.39337 6.60665C6.34241 8.27568 7.72434 9.65761 9.39337 10.6067L10.24 9.75998C10.4213 9.58074 10.6501 9.45722 10.8994 9.40405C11.1488 9.35088 11.4081 9.37029 11.6467 9.45998C12.2516 9.68568 12.88 9.84224 13.52 9.92665C13.8439 9.97234 14.1396 10.1355 14.3511 10.385C14.5625 10.6345 14.6748 10.953 14.6667 11.28Z"
              stroke="#B2B4B4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="contacts-item__text">По телефону</div>
        <div class="contacts-item__contact">
          <a href="tel:+74957995699">+7 495 799 56 99</a> (доб.&nbsp;1333)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterFooter",
};
</script>

<style lang="scss" scoped>
.register-footer {
  width: 100%;
  &__tip {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #b2b4b4;
  }
  .contacts {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 32px;
    margin-top: 32px;
    border-top: 1px solid #ebefee;
    &__title {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #3c4242;
    }
    &-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      &__text {
        margin: 0 12px 0 9px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #b2b4b4;
        flex-shrink: 0;
      }

      &__contact {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #3c4242;
        & a {
          color: #d0006f;
        }

        @media screen and (max-width: 374px) {
          margin-left: auto;
        }
      }
    }
  }
}
</style>