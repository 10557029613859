<template>
  <div class="get-code">
    <div v-if="!refreshCodeButton && !disabled" class="get-code__timer">
      Отправить код повторно <br />можно через {{ timerShow }}
    </div>
    <button
      :disabled="disabled"
      v-else
      @click="refreshCode()"
      class="get-code__refresh-code button button_empty-pink"
    >
      Отправить новый код
    </button>
  </div>
</template>

<script>
export default {
  name: "GetCode",
  props: {
    disabled: Boolean,
    refresh: Function,
    args: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data: () => ({
    timerShow: "01:00",
    refreshCodeButton: false,
    timerCount: null,
  }),
  methods: {
    timer() {
      let timeMinut = parseInt(59);
      this.timerCount = setInterval(() => {
        let seconds = timeMinut % 60; // Получаем секунды
        let minutes = (timeMinut / 60) % 60; // Получаем минуты
        // Условие если время закончилось то...
        if (timeMinut <= 0) {
          // Таймер удаляется
          clearInterval(this.timerCount);
          // Выводит сообщение что время закончилось
          this.refreshCodeButton = true;
          this.timerShow = "01:00";
        } else {
          // Иначе
          // Создаём строку с выводом времени
          let strTimer = `${Math.trunc(minutes)}:${
            seconds < 10 ? "0" + seconds : seconds
          }`;
          // Выводим строку в блок для показа таймера
          this.timerShow = strTimer;
        }
        --timeMinut; // Уменьшаем таймер
      }, 1000);
    },
    refreshCode() {
      clearInterval(this.timerCount);
      this.refreshCodeButton = false;
      this.timer();
      if (this.refresh && typeof this.refresh === "function") {
        this.refresh(this.args);
      }
    },
  },
  mounted() {
    this.timer();
  },
};
</script>

<style scoped lang="scss">
.get-code {
  flex-shrink: 0;
  &__timer {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #b2b4b4;
  }
}
</style>