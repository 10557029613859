<template>
  <div class="register-steps">
    <div class="register-steps__title">{{ title }}</div>
    <div class="register-steps__description" v-if="description">
      {{ description }}
    </div>
    <div class="register-steps__points" v-if="stepList">
      <ul class="register-steps__points-list points-list" v-if="stepList">
        <li
          class="points-list__list-indicator"
          v-for="(i, ind) in stepList"
          :key="ind"
          :class="{ current: ind <= step - 1 }"
        ></li>
      </ul>
    </div>
    <div class="register-steps__descr" v-if="stepList && step">
      {{ stepList[step - 1] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterSteps",
  props: {
    step: {
      type: Number,
      default: 1,
    },
    description: String,
    stepList: Array,
    title: String,
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.register-steps {
  width: 280px;
  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  &__points {
    margin-top: 48px;
    @media screen and (max-width: 767px) {
      margin-top: 24px;
    }
  }

  &__description {
    margin-top: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
  }
  .points-list {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    &__list-indicator {
      width: 100%;
      height: 6px;
      margin-right: 12px;
      background-color: #ebefee;
      border-radius: 99px;
    }
    &__list-indicator:last-child {
      margin-right: 0;
    }
    &__list-indicator.current {
      background: #c4d600;
    }
  }
  &__descr {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3c4242;
  }
}
</style>